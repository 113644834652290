import { render, staticRenderFns } from "./list-control.vue?vue&type=template&id=655aa63c&scoped=true&"
import script from "./list-control.vue?vue&type=script&lang=ts&"
export * from "./list-control.vue?vue&type=script&lang=ts&"
import style0 from "./list-control.vue?vue&type=style&index=0&id=655aa63c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655aa63c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ViewMore: require('/home/circleci/repo/frontend/components/common/view-more.vue').default,BtnDirection: require('/home/circleci/repo/frontend/components/common/btn-direction.vue').default})
