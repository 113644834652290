import { render, staticRenderFns } from "./scientist.vue?vue&type=template&id=0d3f31a1&scoped=true&"
import script from "./scientist.vue?vue&type=script&lang=ts&"
export * from "./scientist.vue?vue&type=script&lang=ts&"
import style0 from "./scientist.vue?vue&type=style&index=0&id=0d3f31a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d3f31a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnDirection: require('/home/circleci/repo/frontend/components/common/btn-direction.vue').default})
