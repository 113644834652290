import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=5f536ecc&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=ts&"
export * from "./Item.vue?vue&type=script&lang=ts&"
import style0 from "./Item.vue?vue&type=style&index=0&id=5f536ecc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f536ecc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ViewMore: require('/home/circleci/repo/frontend/components/common/view-more.vue').default})
