import { render, staticRenderFns } from "./Problem.vue?vue&type=template&id=ea1675d6&scoped=true&"
import script from "./Problem.vue?vue&type=script&lang=ts&"
export * from "./Problem.vue?vue&type=script&lang=ts&"
import style0 from "./Problem.vue?vue&type=style&index=0&id=ea1675d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea1675d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CBtn: require('/home/circleci/repo/frontend/components/content/c-btn.vue').default})
