
import Vue from 'vue'

export default Vue.extend({
  name: 'Feature',
  mounted () {
    if (this.$route.query.section === 'detail') {
      this.$router.push({ query: {} })
      setTimeout(() => this.$scrollTo('#detail', 300), 500)
    }
  }
})
